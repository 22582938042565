.preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s;
}

.preloader--loaded {
  opacity: 0;
}

.preloader span {
  display: block;
  width: 8px;
  height: 5px;
  background: #fff;
  animation: loader 1.5s ease-in-out infinite;
  margin: 0 2px;
  border-radius: 0.5rem;
}

.preloader span:nth-child(2) {
  animation-delay: 0.15s;
}

.preloader span:nth-child(3) {
  animation-delay: 0.3s;
}

.preloader span:nth-child(4) {
  animation-delay: 0.45s;
}

.preloader span:nth-child(5) {
  animation-delay: 0.6s;
}

.preloader span:nth-child(6) {
  animation-delay: 0.75s;
}

.preloader span:nth-child(7) {
  animation-delay: 0.9s;
}

.preloader span:nth-child(8) {
  animation-delay: 1.05s;
}

.preloader span:nth-child(9) {
  animation-delay: 1.2s;
}

.preloader span:nth-child(9) {
  animation-delay: 1.35s;
}
.preloader span:nth-child(10) {
  animation-delay: 1.5s;
}
.preloader span:nth-child(11) {
  animation-delay: 1.65s;
}
.preloader span:nth-child(12) {
  animation-delay: 1.8s;
}
.preloader span:nth-child(13) {
  animation-delay: 1.95s;
}
.preloader span:nth-child(14) {
  animation-delay: 2.1s;
}
.preloader span:nth-child(15) {
  animation-delay: 2.25s;
}

@keyframes loader {
  100% {
    height: 80px;
  }
}
