@import "../parameters.scss";

$breakpointm: 2000px;
$breakpoint0: 1700px;
$breakpoint1: 1400px;
$breakpoint2: 700px;

//height breakpoints:
$breakpointH1: 700px;

.scene {
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
}

.scene-shadow {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  transform-origin: top left;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.scene-layer {
  display: block;
  height: 100%;
  width: 100%;
}

.scene__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./img/vidbackdrop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.scene__clouds1 {
  position: absolute;
  top: 30%;
  left: 0;
  width: 50%;
  height: 100%;
  opacity: 0.9;
  background-image: url(./img/clouds1.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: flyin2 20s ease-out;

  @media (max-width: $breakpoint1) {
    width: 70%;
  }

  @media (max-width: $breakpoint2) {
    width: 120%;
  }
}

.scene__clouds2 {
  position: absolute;
  top: 20%;
  right: 0;
  width: 80%;
  height: 100%;
  opacity: 0.9;
  background-image: url(./img/clouds2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: flyin 40s ease-out;
  transform: translateX(-10%);

  @media (max-width: $breakpoint0) {
    top: 5%;
  }

  @media (max-width: $breakpoint1) {
    top: 5%;
  }

  @media (max-width: $breakpoint2) {
    top: 5%;
    right: -20%;
    width: 150%;
  }
}

.scene__mountains {
  position: absolute;
  bottom: 50px;
  left: -5%;
  width: 110%;
  height: 70%;
  background-image: url(./img/mountains.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;

  @media (min-width: $breakpoint1) {
    bottom: 100px;
  }
}

.scene__mountains2 {
  position: absolute;
  bottom: 50px;
  right: -5%;
  width: 70%;
  height: 85%;
  background-image: url(./img/mountains2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;

  @media (min-width: $breakpoint1) {
    bottom: 70px;
    width: 70%;
  }
}

.scene__logo {
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  height: 60%;
  opacity: 0.7;
  background-image: url(./img/pauljonah.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;

  @media (max-width: $breakpoint1) {
    height: 50%;
  }

  @media (max-width: $breakpoint2) {
    height: 30%;
    background-size: 70%;
  }
}

.scene__paul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 100px;
    left: 50%;
    width: 65%;
    height: 65%;
    min-width: 500px;
    min-height: 500px;
    transform: translateX(-50%);
    background-image: url(./img/paulfront.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
    animation: appear 20s linear infinite;
  }

  &::after {
    background-image: url(./img/paulback.png);
    animation: appear2 20s linear infinite;
  }
}

.scene__ground {
  position: absolute;
  bottom: -10%;
  left: -10%;
  width: 120%;
  height: 40%;
  background-image: url(./img/ground.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  @media (min-width: $breakpoint0) {
    height: 45%;
  }

  @media (min-width: $breakpointm) {
    height: 55%;
  }

  @media (min-height: $breakpointH1) {
    height: 35%;
  }
}

.scene__video {
  position: relative;
  object-fit: cover; /* This is the mainly problematic line*/
  overflow: hidden;
  width: 100%;
  height: 70%;

  @media (min-width: $breakpointm) {
    height: 80%;
  }
}

@keyframes flyin {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-10%);
  }
}

@keyframes flyin2 {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes appear {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear2 {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.scroll-indicator {
  @include text-font;
  text-align: center;
  color: #fff;
  position: absolute;
  opacity: 0.7;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  font-size: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  bottom: 20px;
  left: 50%;
  margin-left: -30px;

  span {
    position: absolute;
    top: -100%;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
    animation: scrollbounce 1s ease infinite;
  }
}

@keyframes scrollbounce {
  50% {
    transform: translateY(-50%) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
