//colors
$c_accent: #f15922;
$c_marker: #9a0000;

@mixin header-font2 {
  font-family: quatro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin header-font {
  font-family: cortado, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin text-font {
  font-family: 'Indie Flower', cursive;
}

@font-face {
  font-family: 'crayon';
  src: url(./fonts/crayon.ttf)  format('truetype');
}

@mixin marker-font {
  font-family: 'Permanent Marker', cursive;
  color: $c_marker;
}

// responsive mixins
@mixin phone-small-only {
  @media (max-width: 499px) {
    @content;
  }
}

@mixin phone-only {
  @media (max-width: 699px) {
    @content;
  }
}

@mixin medium-only {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin large-only {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin very-large-only {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: 700px) {
    @content;
  }
}